import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import GridWrapper from 'components/GridWrapper';
import { AppAuthHeader } from 'components/Header';
import SubscriptionTable from 'components/SubscriptionTable';
import UpgradeToProButton from 'components/UpgradeToProButton';
import { proPlanTableItems, starterPlanTableItems } from 'components/SubscriptionTable/table-items';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: '100%',
    backgroundColor: theme.blue,
  },
  innerContentContainer: {
    padding: 50,
    backgroundColor: theme.blue,
    [theme.breakpoints.down('xs')]: {
      padding: '50px 20px',
    }
  },
  upgradeToProCta: {
    fontFamily: 'Figtree-SemiBold',
    fontSize: 24,
    lineHeight: '28.8px',
    color: 'white',
    paddingRight: 50,
    '& h1': {
      fontFamily: 'Figtree-Bold',
      fontSize: 36,
      lineHeight: '43.2px',
      color: 'white',
    },
    '& h2': {
      fontFamily: 'Figtree-Bold',
      fontSize: 24,
      lineHeight: '28.8px',
      color: 'white',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingBottom: 40,
    }
  },
  upgradeToProButtonMobile: {
    marginTop: 40,
  },
  cardsContainer: {
    overflow: 'hidden',
  }
}));

const Upgrade = () => {
  const classes = useStyles();

  const { breakpoints } = useTheme();
  const showBottomCta = useMediaQuery(breakpoints.down('sm'));

  return (
    <Grid container className={classes.outerContainer}>
      <GridWrapper isFullWidthChild>
        <Grid
          container
          direction='column'
        >
          <AppAuthHeader />
          <Grid
            container
            direction='row'
            className={classes.innerContentContainer}
          >
            {/** Description and upgrade to pro CTA */}
            <Grid item lg={4} md={12} className={classes.upgradeToProCta}>
              <h1>Pro Plan</h1>
              <p>Amplify your reach with AsqMe Pro! Keep 100% of your earnings and unlock unlimited premium features. Charge per answer or don't charge at all, and directly link your tip jar through ThankBank.</p>
              <UpgradeToProButton className={classes.upgradeToProButton} to='/subscribe'>
                Start AsqMe Pro Free Trial
              </UpgradeToProButton>
            </Grid>
            {/** Cards */}
            <Grid item lg={8} md={12} className={classes.cardsContainer}>
              <Grid container direction='row' spacing={2}>
                <Grid item md={6} sm={12}>
                  <SubscriptionTable 
                    title='Starter Plan'
                    subtitle='Free with 80/20 Split'
                    description='Great for creators who receive about 10 questions a month, or anyone who wants to try AsqMe for free. No credit card required.'
                    subSectionTitle='Effective March 1, 2025'
                    subSectionLine1='$3 per Month with 70/30 Split'
                    subSectionLine2='$29 per Year with 70/30 Split'
                    subSectionFooter='Subscribers will be automatically moved to the updated pricing on 3/1/2025.'
                    items={starterPlanTableItems}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <SubscriptionTable
                    isPro
                    title='Pro Plan'
                    subtitle={<>$9 per Month</>}
                    description="Perfect for creators who get more than 10 questions a month, or anyone who needs unlimited access to all of AsqMe’s features."
                    subSectionTitle='Effective March 1, 2025'
                    subSectionLine1='$15 per Month or'
                    subSectionLine2='$99 per Year'
                    subSectionFooter='Subscribers will be automatically moved to the updated pricing on 3/1/2025.'
                    items={proPlanTableItems}
                  />
                </Grid>
              </Grid>
            </Grid>
            {showBottomCta &&
              <UpgradeToProButton extraClasses={classes.upgradeToProButtonMobile} fixedWidth='100%' to='/subscribe'>
                Start AsqMe Pro Free Trial
              </UpgradeToProButton>
            }
          </Grid>
        </Grid>
      </GridWrapper>
    </Grid>
  )
}

export default Upgrade;